<template>
  <v-col cols="0" md="6" class="text-right">
    <div class="hidden-sm-and-down">
      <Contact />

      <Export />
    </div>

    <!-- ------------------ -->
    <!-- ----- MOBILE ----- -->
    <!-- ------------------ -->
    <v-speed-dial class="hidden-md-and-up" v-model="fab" fixed right bottom>
      <template v-slot:activator>
        <v-btn v-model="fab" color="secondary" dark fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-plus</v-icon>
        </v-btn>
      </template>

      <Export />

      <Contact />
    </v-speed-dial>
  </v-col>
</template>

<script>
export default {
  components: {
    Contact: () => import("@/components/AlertDetails/AlertContactModal"),
    Export: () => import("@/components/Exports/ExportAlert"),
  },
  data: () => ({
    fab: false,
  }),
};
</script>
